<script>
    import {UFakeLoader} from 'uloc-vue'
    export default {
      name: 'task-comments-tab-load',
      data () {
        return {}
      },
      computed: {},
      mounted () {
      },
      destroyed () {
      },
      methods: {},
      components: {
        UFakeLoader
      }
    }
</script>

<template>
    <div class="w-full" style="padding: 20px 50px">
        <u-fake-loader width="100%" class="m-t"></u-fake-loader>
        <div class="text-right m-t-lg m-b-lg"><u-fake-loader width="20%" style="" class="m-t"></u-fake-loader></div>
        <div class="text-right m-t m-b"><u-fake-loader width="28%" style="" class="m-t"></u-fake-loader></div>
        <div class="text-left m-t m-b"><u-fake-loader width="25%" style="" class="m-t"></u-fake-loader></div>
    </div>
</template>
